/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
	font-family: 'Open SansSBI';
	src: url('../fonts/OpenSans-SemiBoldItalic.eot');
	src: local('../fonts/Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
		url('../fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
		url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-LightItalic.eot');
	src: local('../fonts/Open Sans Light Italic'), local('OpenSans-LightItalic'),
		url('../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-LightItalic.woff') format('woff'),
		url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Open SansB';
	src: url('../fonts/OpenSans-Bold.eot');
	src: local('../fonts/Open Sans Bold'), local('OpenSans-Bold'),
		url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Bold.woff') format('woff'),
		url('../fonts/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-ExtraBoldItalic.eot');
	src: local('../fonts/Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
		url('../fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
		url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Open SansL';
	src: url('../fonts/OpenSans-Light.eot');
	src: local('../fonts/Open Sans Light'), local('OpenSans-Light'),
		url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Light.woff') format('woff'),
		url('../fonts/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open SansI';
	src: url('../fonts/OpenSans-Italic.eot');
	src: local('../fonts/Open Sans Italic'), local('OpenSans-Italic'),
		url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Italic.woff') format('woff'),
		url('../fonts/OpenSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Open SansR';
	src: url('../fonts/OpenSans-Regular.eot');
	src: local('../fonts/Open Sans Regular'), local('OpenSans-Regular'),
		url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Regular.woff') format('woff'),
		url('../fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open SansSB';
	src: url('../fonts/OpenSans-SemiBold.eot');
	src: local('../fonts/Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-SemiBold.woff') format('woff'),
		url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open SansEB';
	src: url('../fonts/OpenSans-ExtraBold.eot');
	src: local('../fonts/Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
		url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-ExtraBold.woff') format('woff'),
		url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open SansBI';
	src: url('../fonts/OpenSans-BoldItalic.eot');
	src: local('../fonts/Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
		url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-BoldItalic.woff') format('woff'),
		url('../fonts/OpenSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}


$regular: 'Open SansR';
$bold: 'Open SansB';
$boldE: 'Open SansEB';
$boldSB: 'Open SansSB';
$light: 'Open SansL';
$boldBI: 'Open SansBI';
$boldSBI: 'Open SansSBI';

$green: #a9cf54;
$blue: #30cada;

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

@mixin contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}
@mixin cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

* {
	box-sizing: border-box;
	outline: none;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	color: #414141;
	font-size: 16px;
	@media screen and (max-width: 1024px) {
        padding-top: 80px;
    }
    @media screen and (max-width: 768px) {
		font-size: 14px;
    }
}


h1 {
	font-family: $boldE;
	font-size: 42px;
	text-transform: uppercase;
	text-align: center;
	color: #414141;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 42px;
        color: #414141;
        &:hover {
            color: #414141;
        }
    }
	@media screen and (max-width: 1200px) {
		font-size: 32px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 30px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
	}
	@media screen and (max-width: 568px) {
        font-size: 20px;
		margin-bottom: 20px;
		a {
            font-size: 20px;
        }
    }
}
h2 {
	font-family: $boldE;
	font-size: 34px;
	text-transform: uppercase;
	color: #414141;
    margin: 0 0 40px;
	a {
        font-size: 36px;
		color: #414141;
        &:hover {
            color: #414141;
        }
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 30px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
		margin-bottom: 20px;
		a {
			font-size: 22px;
		}
	}
	@media screen and (max-width: 568px) {
        font-size: 20px;
		margin-bottom: 20px;
		a {
			font-size: 20px;
		}
    }
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #414141;
	margin: 0;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 20px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #414141;
	margin: 0;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #414141;
	margin: 0;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	color: #414141;
}
a {
    color: #414141;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #414141;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
	input[type="password"],
	.select{
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 15px 20px;
        margin-bottom: 15px;
		box-shadow: none;
		border: none;
		&::placeholder{
			color: #767676;
		}
	}
	.select{
		position: relative;
		padding: 0;
		width: 50%;
		margin: 0;
		&:after{
			display: none;
		}
		select{
			padding: 0px 15px 0px 12px;
			border: 1px solid #999999;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			font-size: 16px;
			font-family: $regular;
			&::placeholder{
				color: #767676;
			}
		}
		.carat{
			right: 10px;
			&:after{
				content: "\f107";
				position: absolute;
				right: 0;
				top: 0;
				left: 0;
				bottom: 0;
				transform: translateY(-50%);
				font-family: 'FontAwesome';
				font-size: 16px;
				color: #414141;
			}
		}
		.dropdown{
			border: none;
			width: 100%;
			font-size: 16px;
			&.open{
				z-index: 3;
			}
			.selected{
				border: 1px solid #999999;
				color: #767676;
				font-family: $regular;
				font-size: 16px;
			}
			ul{
				border: 1px solid #999999;
				background-color: #f3f3f3;
			}
		}
	}
	@media screen and (max-width: 768px){
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"]
		{
			padding: 11px 20px;
		}
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1024px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
	margin: 0 auto;
	@media screen and (max-width: 1169px){
		width: 100%;
	}
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: $green;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	width: auto!important;
	background-color: $green!important;
	font-family: $boldSB;
	font-size: 16px;
	color: #fff!important;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: none;
	padding: 17px 40px;
	line-height: 1;
	text-transform: uppercase;
	transition: 300ms;
	&:focus, &:active {
		background-color: $blue!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: $blue!important;
		color: #fff!important;
	}	
	@media screen and (max-width: 768px){
		padding: 12px 35px;
		font-size: 14px;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 9px;
			width: 8px;
			height: 8px;
			background-color: $blue;
		}
	}
}

.col--address,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #fff;
		position: absolute;
		top: 3px;
		left: 0;
	}
}
.col--address:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 14px;
	top: 2px;
}
.col--email{
	&:before{
		content: '';
		position: absolute;
		bottom: 0;
		left: 25px;
		right: 0;
		border-bottom: 2px solid rgba(255,255,255,0.5);
	}
	&:hover{
		color: $green;
	}
}
.col--phone{
	a{
		pointer-events: none;
	}
	ul{
		@include ul-default;
	}
	.more--btn{
		pointer-events: auto;
	}
	@media screen and (max-width: 1024px){
		a{
			pointer-events: auto;
		}
	}
}
.more--btn{
	position: absolute;
	top: 3px;
	right: 0;
	font-family: 'FontAwesome';
	color: #fff;
	&:after{
		content: "\f107";
	}
	&.open{
		transform: rotate(180deg);
	}
	&:active, &:focus{
		color: #fff;
	}
}

.title-block{
	position: relative;
	display: table;
	text-align: left;
	padding-bottom: 15px;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 163px;
		border-bottom: 5px solid $blue;
	}
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 163px;
		bottom: 0;
		width: 30px;
		border-bottom: 5px solid $green;
	}
}

.pagination{
	margin-top: 25px;
	ul{
		display: flex;
		justify-content: center;
		@include ul-default;
		li{
			a{
				width: 30px;
				height: 30px;
				color: #414141;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $bold;
				&:hover{
					background-color: $blue;
					color: #fff;
				}
			}
			&.active{
				a{
					background-color: $blue;
					color: #fff;
				}
			}
			&.prev{
				a{
					&:after{
						content: "\f104";
						font-family: 'FontAwesome';
					}
				}
			}
			&.next{
				a{
					&:after{
						content: "\f105";
						font-family: 'FontAwesome';
					}
				}
			}
		}
	}
}

.size-price{
	display: flex;
	align-items: center;
	.select{
		position: relative;
		padding: 0;
		width: 145px;
		margin: 0;
		&:after{
			display: none;
		}
		select{
			padding: 0px 15px 0px 12px;
			border: 1px solid #999999;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			font-size: 16px;
		}
		.carat{
			right: 10px;
			&:after{
				content: "\f107";
				position: absolute;
				right: 0;
				top: 0;
				left: 0;
				bottom: 0;
				transform: translateY(-50%);
				font-family: 'FontAwesome';
				font-size: 16px;
				color: #414141;
			}
		}
	}
}
.new{
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	padding: 5px;
	background-color: $green;
}
.sale{
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	padding: 5px;
	background-color: #ea526f;
}
/* header */
header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 8;
	&.header{
		.bottom{
			box-shadow: 5px 0 8px rgba(0,0,0,0.2);
		}
	}
	&.fixed{
		position: fixed;
		.bottom{
			background-color: #fff;
			box-shadow: 5px 0 8px rgba(0,0,0,0.2);
		}
	}
	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.top{
		background-color: $blue;
		font-size: 14px;
		color: #fff;
		padding: 2px 0;
		a{
			color: #fff;
		}
		.col--phone{
			padding-right: 15px;
			a{
				font-size: 16px;
				color: #fff;
			}
			ul{
				display: none;
				@include ul-default;
				position: absolute;
				right: 0;
				left: 25px;
				padding: 5px;
				background-color: $green;
				box-shadow: 0 0 10px rgba(0,0,0,0.2);
				white-space: nowrap;
			}
		}
		.col--address{
			p{
				margin: 0;
			}
		}
		.col--email{
			font-size: 14px;
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				left: 25px;
				right: 0;
				border-bottom: 2px solid rgba(255,255,255,0.5);
			}
			&:hover{
				color: $green;
			}
		}
	}
	.bottom{
		background-color: rgba(255,255,255,0.85);
		padding: 10px 0;
		.logo{
			display: block;
			width: 250px;
			height: 40px;
			>img{
				@include contain;
			}
		}
		.nav{
			@include ul-default;
			display: flex;
			margin: 0 -20px;
			li{
				margin: 0 20px;
				a{
					font-size: 16px;
					text-transform: uppercase;
					padding: 10px 0;
					display: table;
					font-family: $boldSB;
					border-bottom: 4px solid transparent;
					&:hover{	
						border-bottom: 4px solid $green;
					}
				}
				&.active{
					a{
						border-bottom: 4px solid $green;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1100px) {
		.bottom{
			.nav{
				li{
					margin: 0 10px;
				}
			}
		}
	}
	@media screen and (min-width: 1025px) {
		&.header {
			animation-fill-mode: forwards;
			animation-duration: 300ms;
			animation-name: header1;
			&.f {
				animation-name: header;
				animation-duration: 600ms;
				.bottom{
					background-color: #fff;
					box-shadow: 5px 0 8px rgba(0,0,0,0.2);
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		background-color: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,0.2);
		height: 80px;
		z-index: 9;
		.top{
			padding: 0;
			.col--address{
				display: none;
			}
			.col--email{
				display: none;
			}
			.col--phone{
				display: none;
				position: fixed;
				top: 80px;
				background-color: $green;
				left: 0;
				right: 0;
				margin: 0;
				text-align: center;
				padding: 10px 0;
				&:after{
					display: none;
				}
				.more--btn{
					display: none;
				}
				ul{
					position: relative;
					display: table;
					left: 0;
					right: 0;
					box-shadow: none;
					margin: 0 auto;
					padding: 0;
				}
			}
		}
		.bottom{
			padding: 0;
			.logo{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 180px;
				height: 50px;
			}
			.nav{
				display: none;
				position: fixed;
				top: 80px;
				right: 0;
				left: 0;
				background-color: $green;
				padding: 15px;
				margin: 0;
				>li{
					>a{
						font-size: 14px;
						color: #fff;
						padding: 5px 0;
					}
					&.active{
						a{
							border-bottom: 4px solid $blue;
						}
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: $blue;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.phone--btn {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 20px;
			font-family: 'FontAwesome';
			color: $blue;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			&:after{
				content: '\f095';
			}
			&.open{
				&:after{
					content: '\f00d';
				}
			}
		}
	}
}

@keyframes header1 {
	100% {
		position: absolute;
		top: 0;
	}
}
@keyframes header {
    0% {
      position: fixed;
	  top: -120px;
	}
	100% {
		position: fixed;
		top: 0;
	}
}
/* header */