/* page content */
.page {
    background-color: #f8f8f8;
    padding: 92px 0 70px 0;
	.news1, .news {
        background: none;
		.date {
			color: $blue !important;
		}
	}
	.page_nav {
		.active {
			color: $blue !important;
		}
    }
    .name2{
        a{
            &:hover{
                color: $blue;
                text-decoration: underline;
            }
        }
    }
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: $blue !important;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        border-bottom: 1px solid transparent;
        &:hover{
            color: $blue;
            border-bottom: 1px solid $blue;
        }
    }
    p a {
        color: $blue;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    #photos-list td{
        width: calc(20% - 20px);
        margin: 0 10px;
        display: inline-block;
        vertical-align: top;
      }
    #photos-list td img{
        width: 100%;
    }
    #photos-list td a{
        display: block;
    }
    .text_block{
        ul{
            li{
                a{
                    &:hover{
                        color: $blue;
                    }
                }
            }
        }
    }
    .page-title{
        font-size: 30px;
    }
    @media screen and (max-width: 1200px) {
        padding: 50px 0;
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 0;
        .page-title{
            font-size: 26px;
        }
        #photos-list td{
            width: calc(33.33% - 20px);
        }
		#photos-list td img {
			width: 100%;
			height: auto;
		}
    }
    @media screen and (max-width: 768px) {
        .page-title{
            font-size: 22px;
        }
    }
	@media screen and (max-width: 480px) {
        padding: 0px 0 25px 0;
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
        }
        .page-title{
            font-size: 18px;
        }
    }
    .about{
        background: none;
    }
    .cat{
        background-color: #f8f8f8;
        .cat_block{
            position: relative;
            .new{
                position: absolute;
                top: 12px;
                left: 0;
            }
            .sale{
                position: absolute;
                top: 45px;
                left: 0;
            }
        }
    }
    .furniture{
        background: none;
        .furn_block{
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
        }
        // .container {
        //     padding-top: 100px;
        //     padding-bottom: 100px;
        //     @media screen and (max-width: 1024px) {
        //         padding-top: 45px;
        //         padding-bottom: 45px;
        //     }
        //     @media screen and (max-width: 768px) {
        //         padding-top: 25px;
        //         padding-bottom: 25px;
        //     }
        // }
    }
    .contacts{
        .top{
            .con-list{
                @include ul-default;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                position: relative;
                z-index: 1;
                >li{
                    width: calc(33.33% - 15px);
                    padding: 20px 40px;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 0 5px rgba(0,0,0,0.2);
                }
                .col--address, 
                .col--email{
                    padding-left: 38px;
                    &:after{
                        color: $green;
                        font-size: 24px;
                    }
                    p{
                        margin: 0;
                    }
                }
                .col--email{
                    &:before{
                        border-bottom: 1px solid #7c7c7c;
                        left: 38px;
                        transition: 300ms;
                    }
                    &:hover{
                        &:before{
                            border-bottom: 1px solid $green;
                        }
                    }
                }
                .col--phone{
                    &:after{
                        color: $blue;
                        font-size: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            #map{
                width: 100%;
                height: 590px;
                position: relative;
                top: -30px;
                z-index: 0;
                >ymaps{
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
        .bottom{
            h3{
                font-size: 34px;
                font-family: $boldE;
                text-align: center;
                margin: 30px 0 25px 0;
                text-transform: uppercase;
            }
            form{
                .one-line{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    input[type="text"],
                    input[type="email"]{
                        width: calc(33.3% - 15px);
                        border: 1px solid #e8e8e8;
                        &::placeholder{
                            color: #767676;
                        }
                    }
                    textarea{
                        width: calc(67% - 15px);
                        border: 1px solid #e8e8e8;
                        resize: none;
                    }
                    .g-recaptcha{
                        width: calc(33% - 15px);
                    }
                }
                .btn{
                    margin: 0 auto;
                    padding: 17px 40px;
                }
            }
        }
        @media screen and (max-width: 1024px){
            .top{
                .con-list{
                    margin: 0 0 15px 0;
                    >li{
                        padding: 15px 20px;
                    }
                }
                #map{
                    height: 450px;
                    top: 0;
                }
            }
            .bottom{
                h3{
                    font-size: 30px;
                }
                form{
                    .one-line{
                        textarea{
                            width: calc(50% - 15px);
                        }
                        .g-recaptcha{
                            width: calc(50% - 15px);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px){
            .top{
                #map{
                    height: 350px;
                }
                .con-list{
                    >li{
                        width: 100%;
                        margin: 10px 0;
                    }
                }
            }
            .bottom{
                form{
                    .one-line{
                        input[type="text"],
                        input[type="email"]{
                            width: 100%;
                        }
                        textarea{
                            width: 100%;
                        }
                        .g-recaptcha{
                            width: 100%;
                        }
                    }
                    .btn{
                        margin: 15px 0;
                    }
                }
                h3{
                    font-size: 26px;
                }
            }
        }
        @media screen and (max-width: 400px){
            .top{
                #map{
                    height: 250px;
                }
            }
            .bottom{
                form{
                    .one-line{
                        .g-recaptcha{
                            transform: scale(0.85);
                        }
                    }
                }
            }
        }
    }
    .card{
        .card-top{
            display: flex;
            justify-content: space-between;
            .left{
                width: calc(50% - 30px);
                display: flex;
                .card-slider_for{
                    width: calc(100% - 100px);
                    background-color: #fff;
                    border: 1px solid #e8e8e8;
                    height: 455px;
                    .slick-slide{
                        height: 455px;
                        >img{
                            @include contain;
                        }
                    }
                }
                .card-slider_nav{
                    margin: -7.5px 15px 0 -7.5px;
                    .slick-slide{
                        height: 80px;
                        margin: 7.5px 0;
                        border: 1px solid #e8e8e8;
                        >img{
                            @include cover;
                        }
                    }
                    .slick-arrow{
                        &.slick-prev{
                            display: none;
                        }
                        &.slick-next{
                            top: 85%;
                            right: 0;
                            left: 0;
                            margin: 0 auto;
                            &:before{
                                display: none;
                            }
                            &:after{
                                content: "\f107";
                                font-family: 'FontAwesome';
                                color: #414141;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .right{
                width: calc(50% - 30px);
                h2{
                    font-size: 34px;
                }
                .new{
                    font-size: 14px;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 5px;
                    background-color: $green;
                }
                .size-price{
                    .price{
                        font-size: 24px;
                        margin-left: 70px;
                    }
                    .select{
                        margin-left: 12px;
                        .dropdown{
                            border: none;
                            width: 100%;
                            .selected{
                                border: 1px solid #999999;
                            }
                            ul{
                                border: 1px solid #999999;
                                background-color: #f3f3f3;
                            }
                        }
                    }
                }
                .btn{
                    margin-top: 35px;
                }
            }
        }
        .card-tabs{
            margin-top: 50px;
            box-shadow: 0 0 20px #f5f5f5;
            .tab-menu{
                display: flex;
                @include ul-default;
                margin: 0 -2px;
                li{
                    width: calc(33.33% - 4px);
                    margin: 2px;
                    display: flex;
                    flex: 1 0 auto;
                    a{
                        padding: 20px;
                        background-color: #fff;
                        opacity: 0.5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-family: $bold;
                        flex: 1 0 auto;
                    }
                    &.active{
                        margin: 0;
                        a{
                            opacity: 1;
                        }
                    }
                }
            }
            .tab_block{
                display: none;
                padding: 25px 95px 50px 95px;
                background-color: #fff;
                &:nth-of-type(1){
                    display: block;
                }
            }
            h3{
                font-size: 18px;
                text-transform: uppercase;
                margin: 15px 0;
            }
            .check--list{
                li{
                    margin-bottom: 25px;
                }
            }
            .features{
                @include ul-default;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li{
                    width: calc(50% - 15px);
                    box-shadow: 3px 3px 15px #f5f5f5;
                    margin: 7.5px 0;
                    padding: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .img{
                        width: 55px;
                        height: 55px;
                        border: 2px solid $green;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .text{
                        width: calc(100% - 70px);
                        margin-left: 15px;
                    }
                }
            }
        }
        .character{
            margin: 30px 0;
            border-collapse: collapse;
            width: 400px;
            tr{
                padding: 10px 0;
                td{
                    font-size: 14px;
                    border-bottom: 1px solid #dddddd;
                    padding: 5px 20px 5px 0;
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px){
            .card-top{
                .right{
                    h2{
                        font-size: 28px;
                    }
                    .size-price{
                        .price{
                            font-size: 22px;
                            margin-left: 50px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 990px){
            .card-top{
                .right{
                    width: calc(50% - 15px);
                    h2{
                        font-size: 26px;
                    }
                    table{
                        width: auto;
                    }
                }
                .left{
                    width: calc(50% - 15px);
                }
            }
            .card-tabs{
                .tab-menu{
                    margin: 0;
                    flex-wrap: wrap;
                    li{
                        width: 100%;
                        margin: 0;
                        a{
                            padding: 10px;
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                }
                .tab_block{
                    padding: 15px 15px;
                }
            }
        }
        @media screen and (max-width: 768px){
            .card-top{
                flex-wrap: wrap;
                .left{
                    order: 2;
                    width: 100%;
                    .card-slider_for{
                        .slick-slide{
                            height: 400px;
                        }
                    }
                }
                .right{
                    width: 100%;
                    margin: 0 0 20px 0;
                    order: 1;
                    h2{
                        font-size: 22px;
                    }
                }
            }
            .card-tabs{
                margin-top: 25px;
                h3{
                    font-size: 16px;
                }
                .tab_block{
                    .features{
                        li{
                            width: 100%;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 568px){
            .card-top{
                .left{
                    .card-slider_for{
                        .slick-slide{
                            height: 350px;
                        }
                    }
                }
                .right{
                    h2{
                        font-size: 18px;
                    }
                    .size-price{
                        flex-wrap: wrap;
                        .price{
                            margin: 10px;
                            font-size: 18px;
                        }
                    }
                    .btn{
                        margin-top: 10px;
                    }
                }
            }
            .card-tabs{
                .tab_block{
                    .check--list{
                        li{
                            margin-bottom: 10px;
                        }
                    }
                    .features{
                        li{
                            padding: 15px;
                        }
                    }
                }
            }
            .character{
                width: auto;
            }
        }
        @media screen and (max-width: 400px){
            .card-top{
                .left{
                    .card-slider_for{
                        .slick-slide{
                            height: 300px;
                        }
                    }
                    .card-slider_nav{
                        .slick-arrow.slick-next{
                            top: 97%;
                        }
                    }
                }
            }
            .card-tabs{
                .tab_block{
                    .features{
                        li{
                            display: block;
                            .img{
                                margin: 10px auto;
                            }
                            .text{
                                margin: 0 auto;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* breadcrumbs */
.bread {
    font-size: 16px;
    padding: 40px 0 45px 0;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #414141;
            @include inline-block;
            font-family: $light;
            position: relative;
            padding: 0 32px 0 0;
			a {
                text-decoration: none;
                font-family: $light;  
                border-bottom: 1px solid transparent;
                &:hover{
                    color: $blue;
                    border-bottom: 1px solid $blue;
                }              
			}
			&:after {
				content: "\f105";
                position: absolute;
                top: 3px;
                right: 10px;
                color: #414141;
                font-family: 'FontAwesome';
                font-size: 16px;
				@include inline-block;
            }
            &:nth-of-type(1){
                padding: 0 42px 0 0;
                a{
                    font-size: 18px;
                    font-family: 'FontAwesome';
                    color: $green;
                    display: block;
                    transition: 300ms;
                    position: relative;
                    &:after{
                        content: "\f015";
                        position: absolute;
                        top: 2px;
                    }
                    &:hover{
                        &:after{
                            color: $blue;
                        }
                    }
                }
            }
			&:last-of-type:after {
				display: none;
			}
		}
    }
    @media screen and (max-width: 768px){
        padding: 25px 0;
        font-size: 14px;
    }
}
/* breadcrumbs */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 45px;
            background-color: #fff;
            border-radius: 0px;
            background-color: #fff;
            font-family: $regular;
            font-size: 18px;
            color: #414141;
            border: 1px solid #bababa;
            padding: 15px 20px;
            margin-bottom: 20px;
            box-shadow: none;
            line-height: 1;
            &::placeholder {
                color: #414141;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 50px;
                font-size: 16px;
                padding: 14px 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 180px;
    display: table;
    background-color: $green;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-sizing: border-box;
    outline: none!important;
    z-index: 3;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
        z-index: 1;
        position: relative;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: $green;
        opacity: 0.6;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        background-color: $green;
        opacity: 0.8;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 50px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */