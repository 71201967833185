/* footer */
footer{
    .footer-top{
        background-color: $blue;
        position: relative;
        font-size: 16px;
        color: #f1fbfd;
        .container{
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 70px 15px 75px 15px;
        }
        h3{
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            margin-bottom: 15px;
        }
        a{
            color: #f1fbfd;
        }
        ul{
            @include ul-default;
            li{
                margin-bottom: 12px;
                a{
                    color: #f1fbfd;
                    font-size: 16px;
                    &:hover{
                        color: $green;
                    }
                }
                &.active{
                    a{
                        color: #414141;
                    }
                }
            }
        }
        .col--phone{
            margin: 13px 0;
            ul{
                li{
                    margin: 0;
                }
            }
        }
        .col--address{
            p{
                margin: 0;
            }
        }
        .footer-col{
            margin: 0 10px;
            &.logo{
                .logo{
                    width: 296px;
                    height: 44px;
                    display: block;
                    >img{
                        @include contain;
                    }
                }
                p{
                    font-size: 16px;
                    padding: 15px 0 28px 0;
                    margin: 0;
                }
                .social{
                    display: flex;
                    @include ul-default;
                    li{
                        a{
                            margin: 0 9px;
                            i{
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px){
            h3{
                margin-bottom: 10px;
                font-size: 20px;
            }
            .container{
                padding: 45px 15px;
            }
            .footer-col{
                margin: 10px;
                &.logo{
                    .logo{
                        width: 250px;
                    }
                    p{
                        margin: 10px 0;
                        padding: 10px 0 0 0;
                        font-size: 16px;
                    }
                }
                &.nav{
                    display: none;
                }
            }
            ul{
                li{
                    margin-bottom: 7px;
                }
            }
        }
        @media screen and (max-width: 400px){
            h3{
                font-size: 18px;
            }
            .container{
                padding: 25px 15px;
            }
        }
    }
    .footer-bottom{
        padding: 23px 0;
        background-color: $green;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 14px;
                color: #414141;
                margin: 0;
            }
            .artmedia{
                position: relative;
                padding-right: 130px;
                color: #414141;
                font-size: 14px;
                &:hover{
                    color: #faa643;
                }
                >img{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
        @media screen and (max-width: 768px){
            .container{
                display: block;
                text-align: center;
                p{
                    margin-bottom: 10px;
                    font-size: 14px;
                }
                .artmedia{
                    font-size: 14px;
                }
            }
        }
    }
}
/* footer */