/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		height: 750px;
		img {
			display: block;
			@include cover;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
            margin: 0 auto;
            transform: translateY(-50%);
		}
		.slider--text{
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			left: 50%;
			z-index: 1;
			h3{
				font-size: 38px;
				font-family: $boldE;
				text-transform: uppercase;
				text-align: center;
			}
			h4{
				font-size: 30px;
				text-transform: uppercase;
				font-family: $boldSB;
				position: relative;
				text-align: center;
				margin: 35px 0;
				&:before{
					content: '';
					position: absolute;
					left: 15px;
					top: 50%;
					transform: translateY(-50%);
					width: 125px;
					border-top: 2px solid #414141;
				}
				&:after{
					content: '';
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
					width: 125px;
					border-top: 2px solid #414141;
				}
			}
			.btn{
				margin: 0 auto;
				padding: 17px 30px;
			}
		}
		&:before{
			content: '';
			position: absolute;
			top: -180px;
			right: 0;
			left: 0;
			bottom: 0;
			width: 1140px;
			height: 1140px;
			border-radius: 100%;
			margin: 0 auto;
			background-color: rgba(255,255,255,0.3);
		}
		&:after{
			content: '';
			position: absolute;
			top: -90px;
			right: 0;
			left: 0;
			bottom: 0;
			width: 960px;
			height: 960px;
			border-radius: 100%;
			margin: 0 auto;
			background-color: rgba(255,255,255,0.5);
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid $blue;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: $blue;
				}
			}
			&.slick-active {
				button {
					background-color: $blue;
				}
			}
		}
	}
	.slick-arrow{
		width: 35px;
		height: 50px;
		z-index: 1;
		&:before{
			display: none;
		}
		&:after{
			content: '';
			position: absolute;
			background: url(../img/prevG.png) no-repeat center center;
			background-size: contain;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&:hover{
			&:after{
				background: url(../img/prevG.png) no-repeat center center;
			}
		}
	}
	.slick-next{
		right: 50px;
		transform: rotate(180deg) translateY(50%);
	}
	.slick-prev{
		left: 50px;
	}
	@media screen and (max-width: 1680px){
		.slick-slide{
			.slider--text{
				h4{
					font-size: 26px;
				}
			}
		}
	}
	@media screen and (max-width: 1470px){
		.slick-slide{
			.slider--text{
				h3{
					font-size: 36px;
				}
				h4{
					font-size: 22px;
				}
			}
		}
	}
	@media screen and (max-width: 1366px){
		.slick-slide{
			.slider--text{
				h3{
					font-size: 32px;
				}
				h4{
					font-size: 20px;
					&:before{
						left: 0;
						width: 100px;
					}
					&:after{
						right: 0;
						width: 100px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1139px){
		.slick-slide{
			&:before{
				width: 80%;
				height: auto;
				top: 0;
			}
			&:after{
				width: 60%;
				height: auto;
				bottom: 90px;
				top: 90px;
			}
			.slider--text{
				h3{
					font-size: 28px;
				}
				h4{
					margin: 25px 0;
					&:before{
						left: -20px;
					}
					&:after{
						right: -20px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px){
		.slick-slide{
			height: 600px;
			.slider--text{
				h3{
					font-size: 24px;
				}
				h4{
					font-size: 18px;
					&:before{
						width: 14%;
					}
					&:after{
						width: 14%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px){
		.slick-slide{
			height: 500px;
			.slider--text{
				h3{
					font-size: 20px;
				}
				h4{
					&:before{
						display: none;
					}
					&:after{
						display: none;
					}
				}
				.btn{
					padding: 12px 30px;
					font-size: 14px;
				}
			}
		}
	}
	@media screen and (max-width: 568px){
		.slick-slide{
			height: 400px;
			&:before{
				width: 100%;
			}
			&:after{
				width: 80%;
				top: 40px;
				bottom: 40px;
			}
			.slider--text{
				right: 0;
				left: 0;
				transform: translateY(-50%);
				padding: 0 20px;
				h3{
					font-size: 18px;
				}
				h4{
					font-size: 16px;
					&:before{
						display: none;
					}
					&:after{
						display: none;
					}
				}
				.btn{
					padding: 12px 30px;
					font-size: 14px;
				}
			}
		}
	}
	@media screen and (max-width: 400px){
		.slick-slide{
			height: 370px;
		}
	}
}
/* main slider */

/* main content */
.request{
	background-color: #00c0d4;
	h3{
		font-size: 24px;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 30px;
	}
	form{
		display: flex;
		flex-wrap: wrap;
		input[type="text"],
		input[type="email"],
		.select{
			width: calc(33.33% - 30px);
			margin: 0 15px;
			.dropdown{
				height: 100%;
				box-shadow: none;
				.selected{
					border: none;
					padding: 19px 20px;
				}
			}
		}
		.bottom{
			display: flex;
			flex-wrap: wrap;
			padding: 0 15px;
			margin: 10px 0;
		}
		.btn{
			margin: 20px auto 0 20px;
			padding: 15px 40px;
		}
	}
	@media screen and (max-width: 1024px){
		h3{
			font-size: 22px;
			margin-bottom: 25px;
		}
	}
	@media screen and (max-width: 768px){
		h3{
			font-size: 20px;
			margin-bottom: 15px;
		}
	}
	@media screen and (max-width: 690px){
		form{
			input[type="text"],
			input[type="email"],
			.select{
				width: calc(33.33% - 10px);
				margin: 0 5px;
			}
		}
	}
	@media screen and (max-width: 600px){
		h3{
			font-size: 18px;
		}
		form{
			input[type="text"],
			input[type="email"],
			.select{
				width: 100%;
				margin: 5px 0;
			}
			.bottom{
				display: block;
				padding: 0;
				margin: 5px 0;
				.btn{
					margin: 10px 0;
				}
				.g-recaptcha{
					transform: scale(0.85);
					transform-origin: 0;
				}
			}
		}
	}
}

.advantages{
	background-color: #f2f2f2;
	ul{
		display: flex;
		@include ul-default;
		justify-content: space-between;
		li{
			display: flex;
			align-items: center;
			margin: 10px;
			.img{
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.text{
				margin-left: 15px;
				h5{
					margin: 0;
					font-size: 16px;
					p{
						margin: 0;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px){
		ul{
			flex-wrap: wrap;
			li{
				width: 33.33%;
				&:nth-of-type(5), 
				&:nth-of-type(6){
					width: 50%;
				}
			}
		}
	}
	@media screen and (max-width: 768px){
		ul{
			li{
				width: 50%;
				margin: 10px 0;
				&:nth-of-type(5), 
				&:nth-of-type(6){
					width: 50%;
				}
			}
		}
	}
	@media screen and (max-width: 568px){
		ul{
			display: table;
			margin: 0 auto;
			li{
				width: 100%;
				margin: 10px 0;
				&:nth-of-type(5), 
				&:nth-of-type(6){
					width: 100%;
				}
			}
		}
	}
}
.cat{
	background-color: #f2f2f2;
	.container{
		padding-top: 0;
	}
	.cat--list{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -14px;
		.cat_block{
			width: calc(33.33% - 28px);
			margin: 14px;
			display: flex;
			flex-direction: column;
			background-color: #fff;
			box-shadow: 0 0 10px #e9e9e9;
			border: 2px solid transparent;
			position: relative;
			>a{
				flex: 1 0 auto;
			}
			.cat-img{
				height: 270px;
				>img{
					@include contain;
				}
			}
			h3{
				font-size: 18px;
				text-transform: uppercase;
				text-align: center;
				margin: 30px 0 0 0;
				padding: 0 15px;
				flex: 1 0 auto;
			}
			.cat--text{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				position: relative;
				padding: 35px;
				.select{
					position: relative;
					padding: 0;
					width: 50%;
					margin: 0;
					&:after{
						display: none;
					}
					select{
						padding: 0px 15px 0px 12px;
						border: 1px solid #999999;
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						font-size: 16px;
					}
					.carat{
						right: 10px;
						&:after{
							content: "\f107";
							position: absolute;
							right: 0;
							top: 0;
							left: 0;
							bottom: 0;
							transform: translateY(-50%);
							font-family: 'FontAwesome';
							font-size: 16px;
							color: #414141;
						}
					}
					.dropdown{
						border: none;
						width: 100%;
						.selected{
							border: 1px solid #999999;
						}
						ul{
							border: 1px solid #999999;
							background-color: #f3f3f3;
						}
					}
				}
				.price{
					font-size: 18px;
				}
				.more{
					position: absolute;
					top: 72px;
					right: -2px;
					left: -2px;
					background-color: #fff;
					padding: 25px 12px 35px 12px; 
					opacity: 0;
					pointer-events: none;
					transition: 300ms;
					border-bottom: 2px solid transparent;
					border-right: 2px solid transparent;
					border-left: 2px solid transparent;
					z-index: 2;
					overflow: hidden;
					.more-table{
						height: 160px;
						overflow: hidden;
					}
					table{
						border-collapse: collapse;
						margin: 0 auto;
						tr{
							padding: 10px 0;
							td{
								font-size: 14px;
								border-bottom: 1px solid #dddddd;
								padding: 9px 20px 9px 0;
								&:last-child {
									text-align: right;
								}
							}
						}
					}
					.btn{
						margin: 37px auto 0 auto;
					}
				}
			}
			&:hover{
				border: 2px solid $blue;
				.cat--text{
					.more{
						opacity: 1;
						pointer-events: auto;
						border-bottom: 2px solid $blue;
						border-right: 2px solid $blue;
						border-left: 2px solid $blue;
					}
				}
			}
			.new{
                position: absolute;
                top: 12px;
                left: 0;
            }
            .sale{
                position: absolute;
                top: 45px;
                left: 0;
            }
		}
	}
	.goCat{
		margin: 20px auto 0 auto;
		padding: 17px 85px;
		position: relative;
		&:after{
			content: "\f101";
			position: absolute;
			right: 65px;
			top: 50%;
			font-family: 'FontAwesome';
			transform: translateY(-50%);
		}
	}
	@media screen and (max-width: 990px){
		.cat--list{
			margin: 0;
			.cat_block{
				width: calc(33.33% - 10px);
				margin: 5px;
				h3{
					margin: 15px 0 0 0;
				}
				.cat--text{
					padding: 25px;
					.select{
						width: 58%;
					}
					.more{
						position: relative;
						opacity: 1;
						pointer-events: auto;
						top: 0;
						padding: 15px 0;
						right: 0;
						left: 0;
						table{
							tr{
								td{
									padding: 5px 0;
								}
							}
						}
						.btn{
							padding: 15px 40px;
						}
					}
				}
				&:hover{
					.cat--text{
						.more{
							border: none;
						}
					}
				}
			}
		}
		.goCat{
			padding: 15px 40px;
			&:after{
				right: 26px;
			}
		}
	}
	@media screen and (max-width: 870px){
		.cat--list{
			.cat_block{
				width: calc(50% - 10px);
				.cat--text{
					.more{
						display: table;
						margin: 0 auto;
						width: 100%;
						table{
							width: 100%;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px){
		.cat--list{
			margin: 0;
			.cat_block{
				width: 100%;
				margin: 10px 0;
				h3{
					font-size: 16px;
				}
				.cat-img{
					height: 220px;
				}
				.cat--text{
					.more{
						.btn{
							padding: 11px 30px;
						}
					}
				}
			}
		}
		.goCat{
			&:after{
				right: 28px;
			}
		}
	}
}

.furniture{
	background-color: $blue;
	h2{
		color: #fff;
	}
	.furniture-list{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		.furn_block{
			display: flex;
			width: calc(33.33% - 30px);
			margin: 0 15px;
			flex-direction: column;
			border: 2px solid #fff;
			.furn-img{
				height: 270px;
				overflow: hidden;
				>img{
					@include cover;
					transition: 300ms;
				}
			}
			.furn-text{
				background-color: #fff;
				padding: 30px 15px;
				flex: 1 0 auto;
				display: flex;
				justify-content: center;
				align-items: center;				
				h3{
					font-size: 18px;
					text-transform: uppercase;
				}
			}
			&:hover{
				.furn-img{
					>img{
						transform: scale(1.1);
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px){
		.furniture-list{
			.furn_block{
				width: calc(33.33% - 20px);
				margin: 0 10px;
				.furn-text{
					padding: 20px 15px;
					h3{
						font-size: 16px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 660px){
		.furniture-list{
			margin: 0;
			.furn_block{
				width: 100%;
				margin: 10px 0;
				.furn-img{
					height: 220px;
				}
			}
		}
	}
}

.about{
	background: url(../img/matress-bg.jpg) no-repeat center center;
	background-size: cover;
	h1{
		font-family: $boldE;
	}
	h6{
		font-size: 20px;
		font-family: $boldSBI;
		margin: 0;
	}
	h3{
		font-size: 24px;
		text-transform: uppercase;
	}
	.types{
		@include ul-default;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 30px 0;
		li{
			width: calc(50% - 15px);
			background-color: #fff;
			margin: 7.5px 0;
			padding: 30px;
			display: flex;
			span{
				font-size: 72px;
				color: $green;
				font-family: $boldE;
				line-height: 1;
			}
			&:nth-of-type(2){
				span{
					color: $blue;
				}
			}
			&:nth-of-type(3){
				span{
					color: $blue;
				}
			}
			p{
				margin-left: 40px;
			}
		}
	}
	@media screen and (max-width: 1024px){
		h3{
			font-size: 22px;
		}
		.types{
			li{
				padding: 20px;
				p{
					margin-left: 25px;
				}
			}
		}
	}
	@media screen and (max-width: 768px){
		h3{
			font-size: 20px;
		}
		h6{
			font-size: 18px;
		}
		.types{
			margin: 15px 0;
			li{
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 568px){
		h3{
			font-size: 16px;
		}
	}
}

.scheme{
	background-color: $blue;
	h2{
		color: #fff;
	}
	.scheme-list{
		display: flex;
		flex-wrap: wrap;
		.scheme_block{
			width: calc(25% - 28px);
			margin: 0 14px;
			background-color: #fff;
			position: relative;
			padding: 125px 25px 40px 25px;
			.scheme-img{
				position: absolute;
				top: -30px;
				right: 0;
				left: 0;
				width: 130px;
				height: 130px;
				border-radius: 100%;
				background-color: #fff;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: 3px 3px 5px rgba(0,0,0,0.2);	
			}
			.scheme-text{
				text-align: center;
				a{
					color: $green;
					border-bottom: 1px solid #c9e194;
				}
			}
		}
	}
	@media screen and (max-width: 950px){
		.scheme-list{
			.scheme_block{
				width: calc(50% - 28px);
				margin: 20px 14px;
			}
		}
	}
	@media screen and (max-width: 568px){
		.scheme-list{
			.scheme_block{
				width: 100%;
				margin: 20px 0;
				padding: 60px 10px 20px 10px;
				.scheme-img{
					width: 80px;
					height: 80px;
					padding: 20px;
					>img{
						@include contain;
					}
				}
			}
		}
	}
}

.news{
	background-color: #f3f3f3;
	.news-list{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.news_block{
			width: calc(50% - 30px);
			display: flex;
			flex-wrap: wrap;
			box-shadow: 0 0 10px rgba(0,0,0,0.2);
			height: 180px;
			.news-img{
				width: 180px;
				height: 180px;
				>img{
					@include cover;
				}
			}
			.news--text{
				width: calc(100% - 180px);
				padding: 15px 13px;
				background-color: #fff;
				height: 100%;
				transition: 300ms;
				h3{
					font-size: 15px;
					text-transform: uppercase;
					color: $blue;
					margin: 10px 0;
				}
				p{
					margin: 0;
					height: 45px;
					overflow: hidden;
				}
				span{
					line-height: 1;
				}
			}
			&:hover{
				.news--text{
					background-color: $blue;
					color: #fff;
					h3{
						color: #fff;
						text-decoration: underline;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px){
		.news-list{
			.news_block{
				width: calc(50% - 20px);
				height: auto;
				.news-img{
					height: 200px;
				}
				.news--text{
					height: auto;
					h3{
						font-size: 16px;
					}
					p{
						height: auto;
					}
				}
			}
		}
	}
	@media screen and (max-width: 940px){
		.news-list{
			margin: -20px 0;
			.news_block{
				width: 100%;
				margin: 20px 0;
			}
		}
	}
	@media screen and (max-width: 414px){
		.news-list{
			.news_block{
				height: auto;
				.news-img{
					width: 100%;
					height: 150px;
				}
				.news--text{
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
/* main content */
